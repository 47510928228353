<template>
  <div ref="selectElement" class="select">
    <div class="select__input-field flex--align-center" @click="toggleDropdown">
      <span class="select__value">
        <slot name="value"></slot>
      </span>
      <span class="select__expand-button icon-dropdown-down custom-icon"></span>
    </div>
    <div class="dropdown select__dropdown">
      <div
        v-if="open"
        class="options dropdown__options"
        :class="{ 'dropdown__options--align-right': alignRight }"
      >
        <div
          v-for="option of options"
          :key="JSON.stringify(option)"
          class="options__option"
          @click="onItemClick(option)"
        >
          <slot name="option" :option="option">{{ option }}</slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WebshopDropdown',
  props: {
    options: {
      type: Array,
      default: () => []
    },
    alignRight: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      open: false
    };
  },
  methods: {
    toggleDropdown() {
      this.open = !this.open;
      this.toggleWindowClickEventListener(this.open);
    },
    hideDropdownOnClickOutside({ target }) {
      if (this.$refs.selectElement.contains(target)) {
        return;
      }

      this.open = false;
      this.toggleWindowClickEventListener(this.open);
    },
    toggleWindowClickEventListener(addListener) {
      if (addListener) {
        window.addEventListener('click', this.hideDropdownOnClickOutside);

        return;
      }

      window.removeEventListener('click', this.hideDropdownOnClickOutside);
    },
    onItemClick(value) {
      this.$emit('input', value);
      this.toggleDropdown();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/icons';
@import '../../../../assets/scss/helpers/colors';

.select {
  display: inline-block;
  cursor: pointer;
  width: fit-content;
  color: $tuatara-color;

  &:focus {
    outline: none;
  }

  &__input-field {
    padding-right: 0;
    z-index: 1;
    position: relative;
  }

  &__value {
    color: $tuatara-color;
  }

  &__expand-button {
    margin-left: 5px;
    display: inline-block;
    color: $tuatara-color;
  }
}

.dropdown {
  position: relative;

  &__options {
    box-shadow: 5px 10px 20px rgba(66, 54, 45, 0.1);
    position: absolute;
    top: 5px;
    left: 0;
    background-color: $white;
    min-width: 100%;
    max-height: 150px;
    z-index: 10;
    overflow: auto;

    &--align-right {
      right: 0;
      left: initial;
    }
  }
}

.options {
  padding: 0;
  border-radius: 1px;

  &__option {
    padding: 10px 8px;

    &:hover {
      background-color: $pampas-color;
    }
  }
}
</style>
