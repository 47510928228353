<template>
  <div class="legal-page">
    <header v-if="displayHeader" class="legal-page__header">
      <webshop-dropdown
        class="locale-dropdown"
        align-right
        :options="availableLocales"
        @input="changeLocale"
      >
        <span slot="value" class="locale-dropdown__value">{{ currentLocale }}</span>
        <div slot="option" slot-scope="{ option }" class="locale-dropdown__option">
          {{ option.label }}
        </div>
      </webshop-dropdown>
    </header>
    <slot />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import WebshopDropdown from '@/modules/questionnaire/components/common/WebshopDropdown';

import { getSupportedLocales } from '@/api/places';

import rootTypes from '@/store/types';
import { AVAILABLE_LEGAL_PAGES_LANGUAGE, DEFAULT_LOCALE } from '@/constants';

const AVAILABLE_LOCALES = Object.keys(AVAILABLE_LEGAL_PAGES_LANGUAGE);

export default {
  name: 'LegalPageTemplate',
  components: { WebshopDropdown },
  props: {
    locale: {
      type: String,
      default: ''
    },
    scrollToId: {
      type: String,
      default: ''
    },
    displayHeader: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    currentLocale() {
      const { locale } = this.$i18n;

      return AVAILABLE_LOCALES.includes(locale) ? locale : DEFAULT_LOCALE;
    },
    availableLocales() {
      return getSupportedLocales()
        .filter(([locale]) => AVAILABLE_LOCALES.includes(locale))
        .map(([locale, label]) => ({ locale, label: label.toLocaleLowerCase() }));
    }
  },
  async mounted() {
    if (this.locale) {
      await this.changeLocale({
        locale: AVAILABLE_LOCALES.includes(this.locale) ? this.locale : DEFAULT_LOCALE
      });
    }

    if (!this.scrollToId) {
      return;
    }

    this.$scrollTo(`#${this.scrollToId}`, { offset: -15 });
  },
  methods: {
    ...mapActions({
      setLocale: rootTypes.actions.SET_LOCALE
    }),
    async changeLocale({ locale }) {
      if (locale === this.currentLocale) {
        return;
      }

      await this.setLocale(locale);

      const { name, params } = this.$router.currentRoute;

      this.$router.replace({
        name,
        params: {
          ...params,
          locale
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/helpers/colors';

.legal-page {
  background-color: $white;
  color: $tuatara-color;
  padding-top: 20px;
  min-height: 100vh;
  font-family: var(--u-font-family-Gilroy);

  &__header {
    padding-right: 20px;
    display: flex;
    justify-content: flex-end;
  }
}

.locale-dropdown {
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;

  &__value,
  &__option {
    text-transform: capitalize;
  }

  &__option {
    padding: 0 4px;
    line-height: 14px;
  }
}

@media (min-width: 600px) {
  .legal-page {
    padding-top: 20px;

    &__header {
      padding-right: 2.6%;
    }
  }

  .locale-dropdown {
    &__option {
      padding: 0 8px;
    }
  }
}

@media (min-width: 960px) {
  .legal-page {
    padding-top: 35px;
  }

  .locale-dropdown {
    &__option {
      padding: 0 8px;
    }
  }
}

@media (min-width: 1264px) {
  .legal-page {
    padding-top: 50px;
  }

  .locale-dropdown {
    &__option {
      padding: 0 12px;
    }
  }
}
</style>
